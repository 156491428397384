/*
  This class mocks the functionality of the NFCHelper functions. The mock functions is helpful while developing to bypass NFC check.
  This can be used in following scenario:
    1. To bypass actual NFC check and proceed to further flow while developing and testing using browser.
    2. To bypass actual NFC check and proceed with the phones that does not have NFC in it.
  How to use it?
    1. Change the constant USE_MOCKED_NFC_FOR_DEVELOPMENT in constant file to true to use this while developing.
    2. Use relevant hash in scanData.
    3. Use appropriate NFCReadStatus in getReadStatus.
      SUCCESS -> To simulate nfc hash with CBH as its prefix,
      FAILED -> To simulate nfc not read flow,
      INVALID_HASH -> To simulate nfc hash without CBH.
    4. Please change USE_MOCKED_NFC_FOR_DEVELOPMENT back to false once done with development.
*/
import { NFCReadStatus } from "@src/lib/interface/src/lib/shift";

export class MockNFCHelper {
  static getReadStatus = (facilityNFCHash: string | undefined): NFCReadStatus => {
    return NFCReadStatus.SUCCESS;
  };

  static async deviceNFCCapabilityForShift() {
    return "NFC_ENABLED";
  }

  static async cancelNFCScan() {}

  static async scanData() {
    return "CBH-$2b$10$G.pyhvucMcEemLkqDJUU.uUWGkdEZ9QtqfvWEXjMMuabcuMtoDdom";
  }

  static async readNFCTagInfo(onSuccessRead: (tagData: string) => void) {
    let tagData = "";
    tagData = await MockNFCHelper.scanData();
    if (tagData) {
      onSuccessRead(tagData);
    }
    return tagData;
  }
}
