import { isPlatform } from "@ionic/react";
import { NFC, NfcTag } from "@ionic-native/nfc";
import { logError } from "@src/appV2/lib/analytics";
import { DeviceNFCCapabilityForShift, NFCReadStatus } from "@src/lib/interface/src/lib/shift";

import { MockNFCHelper } from "./mockNfcHelper";
import { USE_MOCKED_NFC_FOR_DEVELOPMENT } from "../../constants";

export type FacilityNFCHash = {
  nfcHash: string;
  isActive: boolean;
};

const parseNfcTag = (tag: NfcTag) => {
  let payload = "";
  if (tag.ndefMessage) {
    const [firstTagMessage] = tag.ndefMessage;
    payload = NFC.bytesToString(firstTagMessage?.payload);
  }
  return payload;
};

let readingTag = false;

const startReaderModeForAndroid = async (onSuccessRead): Promise<string | undefined> => {
  const flags = NFC.FLAG_READER_NFC_A | NFC.FLAG_READER_NFC_V;
  return new Promise((resolve) => {
    NFC.readerMode(flags).subscribe(
      (data) => {
        if (readingTag) {
          readingTag = false;
          resolve(onSuccessRead(parseNfcTag(data)));
        }
      },
      (error) => {
        if (readingTag) {
          logError("startReaderModeForAndroid error", { error });
          readingTag = false;
          resolve("");
        }
      }
    );
  });
};

const startNdefScan = async (onSuccessRead) => {
  try {
    const tag = await NFC.scanNdef();
    return onSuccessRead(parseNfcTag(tag));
  } catch (error) {
    logError("startNdefScan error", { error });
    return "";
  }
};

const readNFCTagInformation = async (onSuccessRead): Promise<string | undefined> => {
  if (isPlatform("ios") || isPlatform("iphone") || isPlatform("ipad")) {
    await startNdefScan(onSuccessRead);
    return;
  } else if (isPlatform("android")) {
    readingTag = true;
    await startReaderModeForAndroid(onSuccessRead);
    return;
  }
  return "";
};

const cancelNfcScan = async () => {
  if (isPlatform("ios") || isPlatform("iphone") || isPlatform("ipad")) {
    await NFC.cancelScan();
  }
};

const deviceNfcCapabilityForShift = async () => {
  try {
    await NFC.enabled();
    return DeviceNFCCapabilityForShift.NFC_ENABLED;
  } catch (error) {
    console.warn("Error in checking NFC capabilities:", error);
    return error;
  }
};

const getReadStatus = (
  facilityNFCHash: string | undefined,
  facilityNFCHashes?: Array<FacilityNFCHash> | undefined
): NFCReadStatus => {
  // NFC not read
  if (facilityNFCHash === undefined || facilityNFCHash === null) {
    return NFCReadStatus.FAILED;
  }
  // NFC hash invalid
  if (!facilityNFCHash.startsWith("CBH-")) {
    return NFCReadStatus.INVALID_HASH;
  }

  if (facilityNFCHashes !== undefined) {
    if (facilityNFCHashes.length === 0) {
      return NFCReadStatus.NO_NFC_HASH_FOR_FACILITY;
    }

    const validHash = facilityNFCHashes.find(
      (facilityNFCHashObj) => facilityNFCHashObj.nfcHash === facilityNFCHash
    );
    if (!validHash) {
      return NFCReadStatus.NFC_HASH_NOT_BELONG_TO_FACILITY;
    }
    if (!validHash.isActive) {
      return NFCReadStatus.INACTIVE_NFC_HASH;
    }
  }

  return NFCReadStatus.SUCCESS;
};

export const getNFCReadStatus = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.getReadStatus
  : getReadStatus;
export const deviceNFCCapabilityForShift = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.deviceNFCCapabilityForShift
  : deviceNfcCapabilityForShift;
export const readNFCTagInfo = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.readNFCTagInfo
  : readNFCTagInformation;
export const cancelNFCScan = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.cancelNFCScan
  : cancelNfcScan;
